var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$vuetify.breakpoint.xs ? 'pa-0' : 'pa-6'},[_c('v-app-bar',{attrs:{"flat":!_vm.$vuetify.breakpoint.xs,"dense":!_vm.$vuetify.breakpoint.xs,"color":_vm.$vuetify.breakpoint.xs ? 'secondary' : 'transparent'}},[(_vm.page === 'provider' || _vm.page === 'register')?_c('v-app-bar-nav-icon',{staticClass:"hidden-md-and-up",attrs:{"dark":""},on:{"click":function($event){_vm.drawer = true}}}):_vm._e(),_c('v-btn',{staticClass:"font-weight-black text-overline",attrs:{"depressed":"","plain":"","dark":"","ripple":false,"to":{ name: 'AdminHomepage' }}},[_c('v-img',{attrs:{"eager":"","src":require("../../../assets/icons/LogoZaturnaHorizontal.svg")}})],1),(_vm.page === 'provider')?_c('v-toolbar-items',{staticClass:"hidden-sm-and-down"},_vm._l((_vm.desktopNav),function(section,i){return _c('v-btn',{key:i,staticClass:"text-none text-caption",attrs:{"depressed":"","plain":"","dark":"","ripple":false,"router":"","exact":section.title === 'AdminHomepage',"to":{ name: section.routerName }},on:{"click":function($event){return _vm.handleStatusChange()}}},[_c('span',{staticClass:"navbartitle"},[_vm._v(_vm._s(section.title))])])}),1):_vm._e(),_c('v-spacer'),(_vm.page === 'provider')?_c('v-toolbar-items',{staticStyle:{"overflow-x":"hidden"}},[_c('v-menu',{attrs:{"offset-y":"","offset-x":"","open-on-hover":!_vm.$vuetify.breakpoint.xs,"rounded":"md","transition":"slide-y-transition","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-background-transparent pr-0 pr-md-5",class:{
              'text-none text-caption': true,
            },attrs:{"depressed":"","text":!_vm.$vuetify.breakpoint.xs,"icon":_vm.$vuetify.breakpoint.xs,"dark":"","ripple":false}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"hidden-sm-and-up",attrs:{"size":"20","right":""}},[_vm._v("fa-ellipsis-v")]),_c('v-icon',{staticClass:"hidden-xs-only mr-2"},[_vm._v("fa-user-circle")]),_c('span',{staticClass:"hidden-xs-only"},[_vm._v(" Serena")])],1)]}}],null,false,1629076723)},[_c('v-card',{attrs:{"tile":"","min-width":"180px"}},[_c('v-list',{staticClass:"py-0",attrs:{"dense":""}},_vm._l((_vm.menu),function(item,i){return _c('v-list-item',{key:i,class:i == 0 ? 'pt-1' : i == _vm.menu.length - 1 && 'pb-1',on:{"click":function($event){return _vm.handleMenuRedirect(item.routeName)}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-subtitle-1",class:{
                    'font-weight-black': item.bold,
                  },domProps:{"textContent":_vm._s(item.title)}})],1)],1)}),1)],1)],1)],1):_vm._e()],1),(_vm.page === 'provider')?_c('v-navigation-drawer',{attrs:{"absolute":"","temporary":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{attrs:{"active-class":"secondary--text"},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}},_vm._l((_vm.mobileNavBottom),function(item,i){return _c('v-list-item',{key:i,attrs:{"router":"","to":{ name: item.routerName }},on:{"click":function($event){return _vm.handleStatusChange()}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}})],1)}),1)],1)]},proxy:true}],null,false,4218393046),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-btn',{staticClass:"d-flex justify-center active-class font-weight-black text-overline",attrs:{"depressed":"","plain":"","ripple":false,"to":{ name: 'AdminHomepage' }}},[_c('span',[_vm._v("ZATURNA")])]),_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{attrs:{"active-class":"secondary--text"},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}},_vm._l((_vm.mobileNavTop),function(item,i){return _c('v-list-item',{key:i,attrs:{"router":"","to":{ name: item.routerName },"exact":item.title === 'Inicio'},on:{"click":function($event){return _vm.handleStatusChange()}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}})],1)}),1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }