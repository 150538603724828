<template>
  <v-container fluid ma-0 pa-0>
    <div class="purple-gradient fill-height">
      <Navbar />
    </div>

    <v-container class="pb-0 body-container">
      <v-container class="pb-0">
        <v-row>
          <v-col cols="12">
            <h1 class="mt-10">Atención al cliente</h1>
          </v-col>
          <!-- UNCOMMENT WHEN ARCHIVE IS APPLIED TO THE VIEW -->
          <!-- <v-col cols="12" sm="10">
            <v-card class="elevation-0">
              <v-tabs
                v-model="filterResult"
                background-color="white"
                show-arrows
              >
                <v-tabs-slider
                  color="primary"
                  class="text-decoration-none"
                ></v-tabs-slider>
                <v-tab @click="tabChange()">Reportes</v-tab>
                <v-tab @click="tabChange()"> Archivados </v-tab>
              </v-tabs>
            </v-card>
          </v-col> -->
        </v-row>
      </v-container>
      <v-divider></v-divider>

      <v-row>
        <v-col cols="1" md="8"></v-col>
        <v-col cols="10" md="4">
          <v-select
            v-model="select"
            label="Ordenar por"
            :items="searchSelect"
            item-text="state"
            item-value="abbr"
            clearable
            class="rounded-lg mt-5"
            color="primary"
            outlined
            dense
          ></v-select>
        </v-col>
      </v-row>

      <Evaluation-card :evaluations="loadedEvaluations" />
      <v-container
        v-if="showMoreBtn === true && evaluations.length"
        class="d-flex justify-center"
      >
        <Button
          text="Ver más evaluaciones"
          outlined
          color="primary"
          aria-label="Ver más evaluaciones"
          @event="handleShowEvaluations()"
        />
      </v-container>
    </v-container>

    <Footer class="mt-12" />
  </v-container>
</template>

<script>
import Navbar from "@/components/Admin/Shared/Navbar.vue";
import EvaluationCard from "@/components/Admin/Customer_Service/EvaluationCard.vue";
import Button from "@/components/Shared/Button.vue";
import Footer from "@/components/Shared/Footer.vue";

export default {
  components: {
    Navbar,
    EvaluationCard,
    Button,
    Footer,
  },
  data: () => ({
    nameFilter: "",
    averageRecomendation: 8,
    filterResult: 0,
    select: null,
    evaluationShow: 3,
    showMoreBtn: true,
    searchSelect: ["Mas reciente", "Menos reciente"],
    evaluations: [
      {
        id: 21,
        clientName: "Name McName",
        evaluationDate: "00/00/0000",
        clientImg: "https://cdn.vuetifyjs.com/images/john.jpg",
        type: "client",
        serviceType: "Decoración CUU",
        msgTitle: "Su servicio no cumple con lo que ofrece ",
        fullMsg:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
      },
      {
        id: 212,
        clientName: "Name",
        evaluationDate: "00/00/0000",
        clientImg: "https://cdn.vuetifyjs.com/images/john.jpg",
        type: "client",
        serviceType: "Decoración CUU",
        msgTitle: "Se hace pasar por otra persona",
        fullMsg:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
      },
      {
        id: 22,
        clientName: "Name",
        evaluationDate: "00/00/0000",
        clientImg: "https://cdn.vuetifyjs.com/images/john.jpg",
        type: "provider",
        serviceType: "Decoración CUU",
        msgTitle: "Se hace pasar por otra persona",
        fullMsg:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
      },
      {
        id: 2,
        clientName: "Name",
        evaluationDate: "00/00/0000",
        clientImg: "https://cdn.vuetifyjs.com/images/john.jpg",
        type: "client",
        serviceType: "Decoración CUU",
        msgTitle: "Se hace pasar por otra persona",
        fullMsg:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
      },
    ],
    // archivedEvaluations: [
    //   {
    //     id: 232,
    //     clientName: "Nombre",
    //     evaluationDate: "00/00/0000",
    //     clientImg: "https://cdn.vuetifyjs.com/images/john.jpg",
    //     type: "recent",
    //     serviceType: "Decoración CUU",
    //     msgTitle: "Se hace pasar por otra persona",
    //     fullMsg:
    //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
    //   },
    //   {
    //     id: 112,
    //     clientName: "Namee",
    //     evaluationDate: "00/00/0000",
    //     clientImg: "https://cdn.vuetifyjs.com/images/john.jpg",
    //     type: "recent",
    //     serviceType: "Decoración CUU",
    //     msgTitle: "Se hace pasar por otra persona",
    //     fullMsg:
    //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
    //   },
    //   {
    //     id: 512,
    //     clientName: "Nombre",
    //     evaluationDate: "00/00/0000",
    //     clientImg: "https://cdn.vuetifyjs.com/images/john.jpg",
    //     type: "recent",
    //     serviceType: "Decoración CUU",
    //     msgTitle: "Se hace pasar por otra persona",
    //     fullMsg:
    //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
    //   },
    //   {
    //     id: 1212,
    //     clientName: "Algun Nombre",
    //     evaluationDate: "00/00/0000",
    //     clientImg: "https://cdn.vuetifyjs.com/images/john.jpg",
    //     type: "recent",
    //     serviceType: "Decoración CUU",
    //     msgTitle: "Se hace pasar por otra persona",
    //     fullMsg:
    //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
    //   },
    // ],
  }),

  computed: {
    loadedEvaluations() {
      if (this.filterResult === 0) {
        return this.evaluations.slice(0, this.evaluationShow);
      } else {
        return this.archivedEvaluations.slice(0, this.evaluationShow);
      }
    },
  },
  methods: {
    handleShowEvaluations() {
      if (
        this.filterResult === 0 &&
        this.evaluationShow >= this.evaluations.length
      ) {
        this.showMoreBtn = false;
      } else if (
        this.filterResult === 1 &&
        this.evaluationShow >= this.archivedEvaluations.length
      ) {
        this.showMoreBtn = false;
      } else {
        this.evaluationShow += 3;
      }
    },

    tabChange() {
      this.evaluationShow = 3;
      this.showMoreBtn = true;
    },
  },
};
</script>

<style scoped>
.purple-gradient {
  background-color: rgba(98, 37, 130, 255);
}
</style>
