<template>
  <div :class="$vuetify.breakpoint.xs ? 'pa-0' : 'pa-6'">
    <v-app-bar
      :flat="!$vuetify.breakpoint.xs"
      :dense="!$vuetify.breakpoint.xs"
      :color="$vuetify.breakpoint.xs ? 'secondary' : 'transparent'"
    >
      <v-app-bar-nav-icon
        v-if="page === 'provider' || page === 'register'"
        dark
        class="hidden-md-and-up"
        @click="drawer = true"
      ></v-app-bar-nav-icon>
      <v-btn
        depressed
        plain
        dark
        :ripple="false"
        :to="{ name: 'AdminHomepage' }"
        class="font-weight-black text-overline"
      >
        <v-img eager src="../../../assets/icons/LogoZaturnaHorizontal.svg" />
      </v-btn>
      <v-toolbar-items v-if="page === 'provider'" class="hidden-sm-and-down">
        <v-btn
          v-for="(section, i) in desktopNav"
          :key="i"
          depressed
          plain
          dark
          :ripple="false"
          class="text-none text-caption"
          router
          :exact="section.title === 'AdminHomepage'"
          :to="{ name: section.routerName }"
          @click="handleStatusChange()"
        >
          <span class="navbartitle">{{ section.title }}</span>
        </v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-toolbar-items v-if="page === 'provider'" style="overflow-x: hidden">
        <v-menu
          offset-y
          offset-x
          :open-on-hover="!$vuetify.breakpoint.xs"
          rounded="md"
          transition="slide-y-transition"
          max-width="200"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              :text="!$vuetify.breakpoint.xs"
              :icon="$vuetify.breakpoint.xs"
              dark
              :ripple="false"
              class="btn-background-transparent pr-0 pr-md-5"
              :class="{
                'text-none text-caption': true,
              }"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon size="20" right class="hidden-sm-and-up"
                >fa-ellipsis-v</v-icon
              >
              <v-icon class="hidden-xs-only mr-2">fa-user-circle</v-icon>
              <span class="hidden-xs-only"> Serena</span>
            </v-btn>
          </template>
          <v-card tile min-width="180px">
            <v-list dense class="py-0">
              <v-list-item
                v-for="(item, i) in menu"
                :key="i"
                :class="i == 0 ? 'pt-1' : i == menu.length - 1 && 'pb-1'"
                @click="handleMenuRedirect(item.routeName)"
              >
                <v-list-item-content>
                  <v-list-item-title
                    class="text-subtitle-1"
                    :class="{
                      'font-weight-black': item.bold,
                    }"
                    v-text="item.title"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>
    <v-navigation-drawer
      v-if="page === 'provider'"
      v-model="drawer"
      absolute
      temporary
    >
      <!-- // ! Change to zaturna logo -->
      <v-btn
        depressed
        plain
        :ripple="false"
        :to="{ name: 'AdminHomepage' }"
        class="d-flex justify-center active-class font-weight-black text-overline"
      >
        <span>ZATURNA</span>
      </v-btn>
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="secondary--text">
          <v-list-item
            v-for="(item, i) in mobileNavTop"
            :key="i"
            router
            :to="{ name: item.routerName }"
            :exact="item.title === 'Inicio'"
            @click="handleStatusChange()"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <template v-slot:append>
        <v-list nav dense>
          <v-list-item-group v-model="group" active-class="secondary--text">
            <v-list-item
              v-for="(item, i) in mobileNavBottom"
              :key="i"
              router
              :to="{ name: item.routerName }"
              @click="handleStatusChange()"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Auth } from "aws-amplify";

export default {
  props: {
    page: { type: String, default: "provider" },
  },
  data: () => ({
    drawer: false,
    group: null,
    mobileNavTop: [
      {
        title: "Inicio",
        routerName: "AdminHomepage",
        icon: "fa-home",
      },
      {
        title: "Cuentas",
        routerName: "AdminAccounts",
        icon: "fa-list",
      },
      {
        title: "Solicitudes",
        routerName: "AdminRequests",
        icon: "fa-list",
      },
      {
        title: "Reservaciones",
        routerName: "AdminReservations",
        icon: "fa-list",
      },
      {
        title: "Evaluaciones",
        routerName: "AdminEvaluations",
        icon: "fa-list",
      },
      {
        title: "Atención al cliente",
        routerName: "AdminCustomerService",
        icon: "fa-list",
      },
    ],
    mobileNavBottom: [
      {
        title: "Modo Proveedor",
        routerName: "",
        icon: "fa-house-user",
      },
    ],
    desktopNav: [
      {
        title: "Inicio",
        routerName: "AdminHomepage",
      },
      {
        title: "Cuentas",
        routerName: "AdminAccounts",
      },
      {
        title: "Solicitudes",
        routerName: "AdminRequests",
      },
      {
        title: "Reservaciones",
        routerName: "AdminReservations",
      },
      {
        title: "Evaluaciones",
        routerName: "AdminEvaluations",
      },
      {
        title: "Atención al cliente",
        routerName: "AdminCustomerService",
      },
    ],
    menu: [
      {
        title: "Mi perfil",
        routerName: "AdminProfile",
        bold: true,
      },
      {
        title: "Cerrar sesión",
        routerName: "SignOut",
      },
    ],
  }),
  computed: mapState({
    username(state) {
      return state.user;
    },
  }),
  methods: {
    handleMenuRedirect(route) {
      if (route == "SignOut") {
        this.signOut();
      } else {
        this.$router
          .push({
            name: route,
          })
          .catch(() => {});
      }
    },
    async signOut() {
      try {
        await Auth.signOut();
        await this.$store.dispatch("signedIn", false);
        await this.$store.dispatch("saveCognitoUser", null);
        this.$router.push({ name: "MainPage" });
      } catch (error) {
        console.log(error);
      }
    },
    handleStatusChange() {
      this.$emit("handleStatusChange");
    },
  },
};
</script>

<style scoped lang="scss">
.v-btn {
  span {
    font-size: 1.1rem !important;
  }
  &:hover {
    .navbartitle {
      transform: scale(1.01);
    }
    .navbaricon {
      transform: scale(1.04);
    }
  }
}

.btn-cristal {
  background: rgba(255, 255, 255, 0.3) !important;
}

.slide-y-transition-leave-to {
  transform: none;
}

.btn-background-transparent::before {
  background-color: transparent !important;
}
</style>
