<template>
  <v-container>
    <v-container v-if="evaluations.length">
      <v-card
        v-for="(client, index) in evaluations"
        :key="'client' + index"
        width="100%"
        elevation="0"
        class="pa-5"
      >
        <h3 v-if="evaluations[index].type == 'provider'">
          Reporte de perfil de proveedor
        </h3>
        <h3 v-else-if="evaluations[index].type == 'client'">
          Reporte de perfil
        </h3>
        <v-container
          class="d-flex pl-0 flex-column flex-sm-row justify-space-between"
        >
          <v-container class="d-flex pl-0">
            <div>
              <v-img
                :width="width"
                class="rounded-circle"
                :src="`${evaluations[index].clientImg}`"
                :lazy-src="`${evaluations[index].clientImg}`"
              ></v-img>
            </div>
            <div class="ml-6">
              <b>{{ evaluations[index].clientName }}</b>
              <br />
              <i>{{ evaluations[index].evaluationDate }}</i>
            </div>
          </v-container>
        </v-container>

        <div class="mt-2">
          <u>{{ evaluations[index].serviceType }}</u>
          <br />
          <br />
          <i>{{ evaluations[index].msgTitle }} </i>
          <br />
          <br />
          <i>{{ evaluations[index].fullMsg }} </i>
        </div>
        <v-divider class="mt-5"></v-divider>
      </v-card>
    </v-container>
    <v-container v-else>
      <v-card
        width="100%"
        elevation="2"
        class="rounded-lg pa-5 mb-5"
        align="center"
      >
        No se encontraron reportes</v-card
      >
    </v-container></v-container
  >
</template>

<script>
export default {
  props: {
    evaluations: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data: () => ({
    width: 63,
  }),

  computed: {},

  methods: {},
};
</script>

<style scoped></style>
